import React from "react";
interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}
const ArrowRight: React.FC<IconProps> = (props) => {
  return (
    <svg fill="none" height="1em" viewBox="0 0 40 40" width="1em" {...props}>
      <g stroke="#fff">
        <circle
          cx={20}
          cy={20}
          r={19.431}
          strokeOpacity={0.15}
          strokeWidth={1.137}
          transform="rotate(180 20 20)"
        />
        <path
          d="M18.235 14.117L24.118 20l-5.883 5.883"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.961}
        />
      </g>
    </svg>
  );
};

const MemoArrowRight = React.memo(ArrowRight);
export default MemoArrowRight;
