import radioStyles from "../components/stations-list/stations-list.module.scss";
import podcastStyles from "../components/podcats-list/podcast-list.module.scss";
import categoryStyles from "../components/category-list/category-list.module.scss";
import countryStyles from "../components/country-list/country-list.module.scss";
import variables from "../../styles/_variables.module.scss";

export const getFontSizeMultiplier = () => window.innerWidth >= 3840 ? 1.6 : window.innerWidth >= 2560 ? 1.12 : 1;

const getRadioStationBoxWidth = () => Number(radioStyles.radioStationOuterWidth.replace('rem', '')) * 10 * getFontSizeMultiplier()
const getRadioStationBoxWidthMobile = () => Number(radioStyles.radioStationOuterWidthMobile.replace('rem', '')) * 10 * getFontSizeMultiplier()
const getPodcastBoxWidth = () => Number(podcastStyles.podcastOuterWidth.replace('rem', '')) * 10 * getFontSizeMultiplier()
const getCountryWidth = () => Number(countryStyles.countryWidth.replace('rem', '')) * 10 * getFontSizeMultiplier()
const getCategoryBoxWidth = () => Number(categoryStyles.categoryOuterWidth.replace('rem', '')) * 10 * getFontSizeMultiplier();
const getContentMaxWidth = () => (Number(variables.contentMaxWidth.replace('rem', '')) - 4) * 10 * getFontSizeMultiplier();


const getPadding = () => {
  if (window.innerWidth < 421) {
    return 0;
  }
  if (window.innerWidth < 1025) {
    return 32;
  }
  return 50;
}

const getRadioWidth = (isMobile: boolean) => {
  return isMobile ? getRadioStationBoxWidthMobile() : getRadioStationBoxWidth();
}
export const calculateRadioScroll = (scrollLeft: number, substract = false, isMobile = false) => {
  const radioStationWidth = getRadioWidth(isMobile);
  const windowWidth = window.innerWidth - getPadding();
  const startWidth = substract ? scrollLeft - windowWidth : scrollLeft + windowWidth;
  const radioItemsVisible = startWidth / radioStationWidth;
  return startWidth - ((radioItemsVisible) - Math.floor(radioItemsVisible)) * radioStationWidth;
};

export const calculatePodcastScroll = (scrollLeft: number, substract = false, isMobile = false) => {
  const podcastWidth = getPodcastBoxWidth();
  const scrollWidth = window.innerWidth - getPadding();
  const startWidth = substract ? scrollLeft - scrollWidth : scrollLeft + scrollWidth;
  const podcastItemsVisible = startWidth / podcastWidth;
  return startWidth - (podcastItemsVisible - Math.floor(podcastItemsVisible)) * podcastWidth;
};

export const calculateCountryListScroll = (scrollLeft: number, substract = false, isMobile = false) => {
  const countryWidth = getCountryWidth();
  const maxContentWidth = getContentMaxWidth();
  const scrollWidth = window.innerWidth > maxContentWidth ? maxContentWidth : window.innerWidth - getPadding();
  const startWidth = substract ? scrollLeft - scrollWidth : scrollLeft + scrollWidth;
  const countryItemsVisible = startWidth / countryWidth;
  return startWidth - (countryItemsVisible - Math.floor(countryItemsVisible)) * countryWidth;
};

export const calculateCategoryScroll = (scrollLeft: number, substract = false) => {
  const categoryBoxWidth = getCategoryBoxWidth();
  const windowWidth = window.innerWidth - getPadding();
  const startWidth = substract ? scrollLeft - windowWidth : scrollLeft + windowWidth;
  return startWidth - (((startWidth) / categoryBoxWidth) - Math.floor((startWidth) / categoryBoxWidth)) * categoryBoxWidth;
};