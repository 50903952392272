export function splitToChunks<T>(array: T[], parts: number) {
  const arrayCopy = [...array];
  let result = [];
  for (let i = parts; i > 0; i--) {
    result.push(arrayCopy.splice(0, Math.ceil(arrayCopy.length / i)));
  }
  return result;
}

export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: string, value: any) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};