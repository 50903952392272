import clsx from "clsx";
import { Category } from "services-hooks/types";
import styles from "./stations-list.module.scss";
import Image from "next/image";
import MemoLogo from "../icons/Logo";
import { useRef, useEffect } from "react";
import { useIntersection } from "react-use";
import { useIsMobileResolution } from "app/utils/is-mobile-resolution";
import { useAppDispatch } from "app/store/store";
import { radioActions } from "app/store/slices/radio";
import Link from "next/link";

interface StationsRowProps {
  items: Category[];
  wrapped?: boolean;
  column?: boolean;
  navigateStation?: boolean;
  showStationNames?: boolean;
  handleOnClick: (radioStation: Category, index: number) => void;
  scrollSyncId?: string;
  navigationDisabled?: boolean;
  fetchNextPage?: VoidFunction;
  triggerFetchNextPage?: boolean;
}

export const StationsRow: React.FC<StationsRowProps> = ({
  items,
  wrapped,
  showStationNames,
  handleOnClick,
  scrollSyncId,
  navigationDisabled,
  fetchNextPage = () => {},
  triggerFetchNextPage,
  navigateStation = false,
  column,
}) => {
  const dispatch = useAppDispatch();
  const loadMoreTriggerRef = useRef(null);
  const intersection = useIntersection(loadMoreTriggerRef, {
    rootMargin: "0px",
    threshold: 1,
  });

  const isMobile = useIsMobileResolution();
  const onLinkClick = (radioStation: Category) => {
    dispatch(
      radioActions.selectRadio({
        section: items,
        activeIndex: items.indexOf(radioStation),
      })
    );
  };
  useEffect(() => {
    if (triggerFetchNextPage && intersection?.isIntersecting) {
      fetchNextPage();
    }
  }, [intersection, triggerFetchNextPage]);
  return (
    <div
      className={clsx(styles.row, {
        [styles.vertical]: column,
        [styles.wrapped]: wrapped,
      })}
      data-navigate-row={!navigationDisabled}
      data-navigate-scrollable
      data-navigate-scrollable-parent
      data-sync-scroll-position={scrollSyncId}
    >
      {items.map((radioStation, index) => (
        <div
          key={`station-row-${index}-${radioStation.permalink}`}
          data-navigate-row={!navigationDisabled && column}
        >
          <Link
            key={`radio-station-wrap-${index}-${radioStation.permalink}`}
            href={`/${radioStation.permalink}`}
          >
            <a
              data-navigate-item={!navigationDisabled}
              tabIndex={0}
              className={clsx(styles.radio_station, {
                [styles.show_station_names]: showStationNames,
              })}
              onClick={(e) => {
                if (navigateStation) {
                  onLinkClick(radioStation);
                } else {
                  e.preventDefault();
                  handleOnClick(radioStation, items.indexOf(radioStation));
                }
              }}
            >
              {radioStation?.logo || radioStation?.logo ? (
                <Image
                  className={styles.cover}
                  key={`radio-station-${index}-${radioStation.permalink}`}
                  src={radioStation?.logo || radioStation?.logo || ""}
                  width={isMobile ? 96 : 156}
                  height={isMobile ? 96 : 156}
                  layout="fixed"
                  objectFit="cover"
                  alt={radioStation.name}
                  unoptimized
                />
              ) : (
                <div className={clsx(styles.cover, styles.no_image)}>
                  <MemoLogo />
                </div>
              )}
              {showStationNames && (
                <div className={styles.station_title}>{radioStation.name}</div>
              )}
            </a>
          </Link>
        </div>
      ))}
      {triggerFetchNextPage && <div ref={loadMoreTriggerRef} />}
    </div>
  );
};
